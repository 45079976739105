section.cup-champion-section {
    margin-bottom: 30px;

    .header-title {
        background-color: #759bca;
        padding: 10px;
        border-left: 3px solid #264264;
        border-right: 3px solid #264264;
        border-radius: 8px 8px 0 0;
        text-align: center;

        > h2 {
            margin: 0;
            font-size: 24px;
            color: #264264;
        }
    }

    .card-result {
        background-color: #fff;
        border-radius: 0 0 8px 8px;
        border-left: 3px solid #264264;
        border-right: 3px solid #264264;

        .body-match {
            padding: 20px 10px;
            border-bottom: 1px solid #56626c0f;

            .match-teams {
                grid-template-columns: minmax(0, 70px) auto;
                grid-template-areas: "a-1";

                .flag {
                    max-width: 60px;
                    max-height: 60px;
                    width: 90%;
                    height: auto;
                    box-shadow: none;
                }

                h2 {
                    font-size: 40px;
                    text-transform: uppercase;
                }
            }

            .congratulation {
                display: flex;
                justify-content: center;
                font-size: 20px;
                gap: 5px;
                color: #759bca;
                font-weight: 700;
                text-transform: uppercase;
                margin-top: 20px;
            }
        }
    }
}