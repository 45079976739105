
.home-page {
	padding: 3rem;
	background-color: white;
	
	.home-container {
		// padding: 18px 30px 30px;
	}
}

.board-container {
	padding: 1.4rem 0;
}
