.content-competition {
    margin: 3rem 0;

    .calendar-match {
        display: flex;
        justify-content: center;
        font-size: 10px;
        font-style: italic;
        gap: 5px;
    }
    
    .match-teams {
        display: grid;
        // grid-template-columns: minmax(90px,140px) 24px 80px 24px minmax(90px,140px);
        grid-template-columns: 1fr 32px 80px 32px 1fr;
        grid-template-areas: "home-competitor-name a-1 a-1 a-1 away-competitor-name";
        place-items: center;
        justify-content: center;

        .title-content {
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
            // color: $textColor;
            width: 100%;
            position: relative;
            padding: 0 8px;
            box-sizing: border-box;
            
            &.home-competitor-name {
                text-align: right;
                flex-direction: row-reverse;
                grid-area: home-competitor-name;
            }
    
            &.away-competitor-name {
                text-align: left;
                flex-direction: row;
                grid-area: away-competitor-name;
            }
        }
    
        h2 {
            font-size: 14px;
            margin: 0;
        }
    
        .flag {
            max-width: 24px;
            max-height: 24px;
            width: 24px;
            height: 14px;
            object-fit: cover;
            box-shadow: 0px 0px 1px 0px;
        }
    }
}

.table {
    td, th {
        padding: 0;
        vertical-align: middle;
        border-top: unset;
    }

    .scrollable-table_first_cell__3BiNj {
        min-width: 64px;
        border-bottom: 1px solid #ecf0f3;
        font-size: 12px;
        display: -webkit-flex;
        display: -moz-box;
        display: flex;
        height: 40px;
        background-color: inherit;
        -webkit-justify-content: space-between;
        -moz-box-pack: justify;
        justify-content: space-between;
        -webkit-align-items: center;
        -moz-box-align: center;
        align-items: center;
        position: absolute;
        z-index: 10;
    }

    .scrollable-table_group_title__3PBsT {
        padding: 0 6px;
    }

    .scrollable-table_first_cell__3BiNj {
        // left: 0;
        left: 15px;
    }

    .scrollable-table_table_header__332MD .scrollable-table_first_cell__3BiNj {
        margin-top: 0;
        height: 32px;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-justify-content: start;
        -moz-box-pack: start;
        justify-content: start;
    }

    .scrollable-table_table_header_cell__13H2P {
        -webkit-flex: none;
        -moz-box-flex: 0;
        flex: none;
        text-align: center;
        font-size: 12px;
        color: #7f97ab;
        font-weight: 400;
        padding: 0 6px;
        white-space: nowrap;
    }

    .scrollable-table_transparent_cell__1WPUn {
        position: relative;
        min-width: 64px;
        width: 64px;
    }

    .scrollable-table_table_text_bold_cell__S8zfb {
        font-weight: 500;
        color: #292f33;
    }

    .scrollable-table_table_cell_border__NWba_ {
        border-left: 1px solid #ecf0f3;
    }

    .scrollable-table_table_header__332MD {
        height: 32px;
        background-color: #fff;
        border-bottom: 1px solid #ecf0f3;
        border-top: 1px solid #ecf0f3;
        padding-top: 16px;
        padding-right: 3px;
    }

    .scrollable-table_table_text_major_cell__2tzxx {
        padding: 0 4px;
    }

    .standings-widget_competitor_link__3N7jG {
        width: 100%;
    }

    .standings-widget_competitor_link__3N7jG, .standings-widget_competitor_logo__2iYGH {
        display: flex;
        align-items: center;
    }

    a {
        color: #505050;
        text-decoration: none;
        cursor: pointer;
    }

    .standings-widget_table_row_destination__20ciq {
        width: 2px;
        height: 100%;
        position: absolute;
        
        &.clasificated {
            background-color: rgb(43, 224, 212);
        }
    }

    .standings-widget_competitor_index__2F5QE {
        position: relative;
        margin: 7px;
        font-size: 12px;
        text-align: center;
        min-width: 15px;
    }

    img {
        // width: 100px;
        // height: auto;
        // visibility: visible;
		max-width: 24px;
        max-height: 24px;
        width: 24px;
        height: 24px;
        object-fit: contain;
    }

    .standings-widget_competitor_info__kU5Vu {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        min-width: 120px;
    }

    .standings-widget_competitor_name_text__1xvY3 {
        font-size: 13px;
    }

    .standings-table-row {
        height: 40px;
        font-size: 12px;
        border-bottom: 1px solid #ecf0f3;
        background-color: #fff;

        &:nth-child(odd) {
            background-color: #f8f9fa;
        }

        .standings-widget_competitor_name__NTu3D {
            padding-left: 8px;
            text-align: left;
            position: relative;
        }

        .scrollable-table_table_text_cell__1N6uY {
            height: 40px;
            text-align: center;
            font-size: 12px;
            padding: 0 6px;
            white-space: nowrap;
        }
    }
}
