@import './mixins.scss';

$primary: #244ef1;
$secondary: #0b1d2e;
$neutral: #bfbfbf;
$success: #04aa5e;
$info: #d3dcfc;
$warning: #ffb84b;
$error: #e30042;
$black: #1f1f1f;
$white: #ffffff;
$gray-divider: #dcdcdc;
$danger: #ff5e5e;
$gray: #717171;
$gray-secondary: #979797;
$gray-tertiary: #ccc;
$gray-divider: #c8c8c8;
$gray-quaternary: #6d7782;
$gray-background: #e5e5e5;
$gray-placeholder: #666666;

$fontColor: #0b1d2e;
$bgBody: $gray-background;
$input: #f3f3f3;

// keep in mind this color is in the design as secondary/100t
.bg-secondary-96t {
	background-color: tint($secondary, 96);
}
.color-secondary-96t {
	color: tint($secondary, 96);
}
@include key-value-color('color', 'color');
@include key-value-color('bg', 'background-color');
@include key-value-color('border', 'border-color');
