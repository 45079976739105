section.info-league-section {
    background-color: #fff;
    display: flex;
    align-items: center;
    height: 96px;

    .info-league {

        .content-info {
            display: flex;
            align-items: center;
            gap: 20px;

            .logo-league{
                .logo {
                    max-width: 68px;
                    max-height: 68px;
                    width: auto;
                    height: auto;
                    visibility: visible;
                }
            }

            .title {
                font-size: 24px;
                color: #264264;
                margin: 0;
            }

            span {
                color: #769bca;
                font-weight: 600;
            }
        }
    }

}