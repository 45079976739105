@import '../../../../../shared/application/theme/colors.scss';

.blog-home-section {

	> .header-section {

		.title {
			font-family: 'PT Sans', sans-serif;
			font-weight: 700;
			font-size: 1rem;
			text-transform: uppercase;
			border-left: 2px solid #facd17;
			padding-left: 8px;
		}
	}

	> .gallery-blog {
		--of-grid-gap-size: 2.5rem;
		list-style: none;
		margin: 0;
        padding: 0;
		display: grid;
		grid-gap: var(--of-grid-gap-size);
		grid-template-columns: repeat(12,minmax(0,1fr));

		> .gallery-blog-item {
			// border: 1px solid pink;
			grid-column: span 3;

			a {
				text-decoration: none;
			}

			&.featured-post {
				grid-column: span 12;

				.blog-card {
					position: relative;
					max-height: 500px;
					overflow: hidden;

					&::before {
						content: '';
                        position: absolute;
                        top: 0;
						left: 0;
                        width: 100%;
						height: 100%;
						background: linear-gradient(90deg, rgba(38,66,100,1) 16%, rgba(0,212,255,0) 100%);
					}

					.heade {
						height: unset;
	
						> img {
							height: 500px;
							object-fit: cover;
							object-position: top;
						}
					}

					.body {
						position: absolute;
    					top: 0;
						left: 0;
						width: 50%;
						height: 100%;
						display: flex;
						flex-direction: column;
						justify-content: center;
						gap: 20px;
						padding-left: 40px;

						> h3 {
							// font-family: NotoSansDisplay-Black, "Arial Black", sans-serif;
							font-family: 'PT Sans', sans-serif;
							font-weight: 700;
							color: white;
							font-size: 54px;
							padding: 0.1rem 0px;
							display: flex;
							line-height: 52px;
							text-shadow: rgb(16 16 16 / 45%) 0px 0px 5px;
							margin: 0;
							display: unset;
							overflow: unset;
						}

						> .post-summary {
							display: block;
							
							> p {
								// font-family: NotoSansDisplay-Regular, "Arial Black", sans-serif;
								font-family: 'PT Sans', sans-serif;
								font-weight: 700;
								margin: 0;
								font-size: 16px;
								line-height: 22px;
								color: #d7e5f8;
								display: -webkit-box;
								-webkit-line-clamp: 2;
								-webkit-box-orient: vertical;
								overflow: hidden;
							}
						}

						.author {
							font-family: 'PT Sans', sans-serif;
							font-weight: 700;
							display: flex;
							gap: 20px;
							color: #facd178c;

							> .post-date {
								font-weight: 100;
							}
						}
					}
				}
			}

			.blog-card {
				display: grid;
				grid-row-gap: 0.625rem;
				row-gap: 0.4rem;
				min-height: 280px;
				max-height: 280px;

				.heade {
					width: 100%;
					height: 170px;

					> picture {

						> img {
							height: 100%;
							object-fit: cover;
						}
					}
				}

				.body {
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					gap: 20px;

					h3 {
						color: #264264;
						font-size: 18px;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
						overflow: hidden;
					}

					.post-summary {
						display: none;
					}

					.author {
						font-weight: 600;
						display: flex;
						align-items: center;
						gap: 14px;
						font-size: 13px;
						color: #264264;

						.info-author {
							display: flex;
							align-items: center;
							gap: 10px;

							.avatar {
								width: 24px;
								height: 24px;
								object-fit: cover;
								border-radius: 50%;
								overflow: hidden;
								box-shadow: 0 0 2px 0px grey;
							}
						}

						.divider {
							color: #facd17;
						}

						.post-date {
							font-family: "PT Sans", sans-serif;
							font-weight: 100;
						}
					}
				}
			}
		}
	}
}
