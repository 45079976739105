@import '../../../../../shared/application/theme/colors.scss';

section.fixtures-results-section {
    border-radius: 8px;
    overflow: hidden;

    .header-title {
        background-color: white;
        padding: 10px;
        border-left: 3px solid #facd17;
        border-radius: 8px;

        > h2 {
            margin: 0;
            font-size: 18px;
            color: #264264;
        }
    }

    .card-result {
        // border: 1px solid blue;
        background-color: #fff;
        margin: 10px 0;

        .header-match {
            border-bottom: 1px solid #56626c42;
            padding: 10px;

            .result-date {
                display: flex;
                justify-content: space-between;
                font-size: 13px;
                color: #56626c;

                .date-match {
                    text-transform: capitalize;
                }
            }
        
        }

        .body-match {
            padding: 20px 10px;
            border-bottom: 1px solid #56626c0f;

            .score-match {
                gap: 2px;
                font-weight: 600;
            }

			.penalty-section {
				display: grid;
				justify-content: center;
				color: #264264;
				font-size: 11px;
				
				span {
					background-color: #facd17;
					border-radius: 5px;
                    padding: 2px 8px;
					margin-bottom: 4px;
				}
			}
        }
    }
}
