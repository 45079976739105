@import '../../../../../shared/application/theme/colors.scss';

section.fixtures-standings-groups-section {
	.header-title {
        background-color: white;
        padding: 10px;
        border-left: 3px solid #facd17;
        border-radius: 8px;

        > h2 {
            margin: 0;
            font-size: 18px;
            color: #264264;
        }
    }

	.card-result {
        background-color: #fff;
        margin: 10px 0;
	}

	.group-header {
		height: 32px;
		width: 100%;
		padding: 0 8px;
		color: #505050;
		font-weight: 400;
		font-size: 13px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		background-color: #fff;
		border-radius: 8px 8px 0 0;
	}
}
