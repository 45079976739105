.header {
    position: sticky;
    width: 100%;
    top: 0;
    left: 0;
    min-height: 70px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    z-index: 100;

    > .main-menu {
        background-color: #264264;
        padding: 0.94rem 1rem;
        width: 100%;
        height: 100%;
    }

    .content {
        width: 100%;
        text-align: center;

        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;
            justify-content: center;

            li {
                width: 170px;
                position: relative;

                .nav-link {
                    color: #fff;
                    text-decoration: none;
                }

                &:before {
                    content: '';
                    position: absolute;
                    bottom: -15px;
                    left: 0;
                    display: inline-block;
                    width: 100%;
                    height: 3px;
                    background-color: #facd17;
                    opacity: 0;
                    transition: all 0.2s ease-in-out;
                }

                &:hover:before {
                    opacity: 1;
                }

                &.active:before {
                    opacity: 1;
                }

                &.main-logo {
                    // width: 169px;
                    position: relative;
    
                    .content-logo {
                        position: absolute;
                        top: -16px;
                        left: 48px;
                        width: 70px;
                        filter: drop-shadow(0px 6px 0 #264264);
    
                        img {
                            width: 100%;
                        }
                    }

                    &:hover:before {
                        opacity: 0;
                    }
                }
            }

        }
    }
}

$subNavHeight: 17rem;

@keyframes slide-down {
  0% {
      height: 0;
  }
  100% {
      height: $subNavHeight;
  }
}

@keyframes slide-up {
    0% {
      height: $subNavHeight;
    }
    100% {
      height: 0;
    }
}

.sub-nav {
    position: absolute;
    left: 0;
    right: 0;
    top: 4rem;
    padding: 0 2rem;
    display: flex;
    align-items: center;
    // flex-direction: column-reverse;
    overflow: hidden;
    width: 100%;
    height: $subNavHeight;
    background-color: #fff;
    border-bottom: 1px solid #264264;
    // animation: slide-down 0.3s cubic-bezier(0.29, 0.96, 0.56, 1.00) both;
    z-index: -1;

    &.show {
        animation: slide-down 0.3s cubic-bezier(0.29, 0.96, 0.56, 1.00) both;
    }

    &.closing {
        // animation: slide-up 0.25s cubic-bezier(0.45, 0.06, 0.60, 0.13) 0.65s;
        animation: slide-up 0.35s cubic-bezier(0.45, 0.06, 0.60, 0.13) both;
    }

    .sub-menu {
        list-style: none;
        margin: 0;
        padding: 0;
        display: grid;
        // grid-template-columns: repeat(3, minmax(0, 1fr)) 3fr;
        grid-template-columns: repeat(2, minmax(0, 3fr)) 3fr;

        li {
            display: inline-block;
            transition: cubic-bezier(0.075, 0.82, 0.165, 1) .3s;

            &:hover {
                border-radius: 3px;
                background-color: #779bca1f;
            }

            .nav-link {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 10px;
                padding: 0 1rem;
                height: 48px;
                color: #264264;

                .logo {
                    width: 24px;
                    height: 24px;
        
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }

                span {
                    font-size: 12px;
                    font-weight: 600;
                }
            }
        }

    }
}


  footer.footer-alz {
    background-color: #264264;
    color: #fff;
    padding: 70px 0 10px;

    a {
      color: #fff;
      text-decoration: none;
    }

    .brand-block {
        display: flex;
        align-items: center;
        gap: 20px;

        .content-img {
            width: 234px;

             img {
                width: 100%;
             }
        }

        p {
            margin: 0;
            line-height: 20px;
        }
    }

    .legal-block {
        border-top: 1px solid #54616d;
        font-size: 13px;
        line-height: 20px;
        margin-top: 50px;
        padding: 32px 0 30px;
    }
  }