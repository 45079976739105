@import '../../../../../shared/application/theme/colors.scss';

.post-section {
    padding: 3rem;

    .content-post {
        background-color: #fff;

        > .header-post {
            padding: 30px;
            position: relative;

            .image-header {
                height: 530px;
                overflow: hidden;
            }

            .content-header {
                position: absolute;
                bottom: 6px;
                width: 75%;

                > .title {
                    margin: 0;

                    > span {
                        background-color: #fff;
                        box-shadow: 1.25rem 0 0 #fff;
                        box-decoration-break: clone;
                        margin: 0;
                        font-size: 4rem;
                        line-height: 80px;
                    }
                }
            }
        }

        > .body-post {
            padding: 30px;

            .author {
                display: flex;
                align-items: center;
                gap: 20px;
                margin-bottom: 20px;

                .info-author {
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    .avatar {
                        width: 30px;
                        height: 30px;
                        object-fit: cover;
                        border-radius: 50%;
                        overflow: hidden;
                        box-shadow: 0 0 2px 0px grey;
                    }

                    span {
                        font-weight: 700;
                    }
                } 

                .divider {
                    color: #88a8d2;
                }
            }

            .post-summary {
                border-top: 1px dashed #facd17;
                padding: 30px 0 20px;

                ul {
                    margin: 0;
                    padding: 0 0 0 16px;
                }
            }

            .pauta {
                border: 2px dotted gray;
                height: 400px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
}