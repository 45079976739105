section.fixtures-standings-section {
	margin-top: 30px;

	.header-title {
        background-color: white;
        padding: 10px;
        border-left: 3px solid #facd17;
        border-radius: 8px;

        > h2 {
            margin: 0;
            font-size: 18px;
            color: #264264;
        }
    }

	.content-standings {
		margin-top: 10px;
		border-radius: 8px;
		overflow: hidden;

		.group-header {
			height: 32px;
			width: 100%;
			padding: 0 8px;
			color: #505050;
			font-weight: 400;
			font-size: 13px;
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-top: 4px;
			background-color: #fff;
			border-radius: 8px 8px 0 0;
		}

		.table {
			.scrollable-table_first_cell__3BiNj {
				border-top-left-radius: 8px
			}
		}
	}
}