section.match-live-section {

    .header-title {
        background-color: #facd17;
        padding: 10px;
        border-left: 3px solid #264264;
        border-radius: 8px;

        > h2 {
            margin: 0;
            font-size: 18px;
            color: #264264;
        }
    }

    .card-result {
        background-color: #fff;
        margin: 10px 0;

        .body-match {
            padding: 20px 10px;
            border-bottom: 1px solid #56626c0f;

			.penalty-section {
				display: grid;
				justify-content: center;
				color: #264264;
				font-size: 11px;
				
				span {
					background-color: #facd17;
					border-radius: 5px;
                    padding: 2px 8px;
					margin-bottom: 4px;
				}
			}
        }
    }

    .match-live-time {
        display: flex;
        justify-content: center;
        font-size: 13px;
        font-weight: 700;
        color: #43a38d;
    }
}

.second-line {
    animation: time 10s linear infinite;

    @keyframes time {
        0%, 9.99% {
            content: "0";
            opacity: 1;
        }
        10%, 19.99% {
            content: "1";
            opacity: 0;
        }
        20%, 29.99% {
            content: "2";
            opacity: 1;
        }
        30%, 39.99% {
            content: "3";
            opacity: 0;
        }
        40%, 49.99% {
            content: "4";
            opacity: 1;
        }
        50%, 59.99% {
            content: "5";
            opacity: 0;
        }
        60%, 69.99% {
            content: "6";
            opacity: 1;
        }
        70%, 79.99% {
            content: "7";
            opacity: 0;
        }
        80%, 89.99% {
            content: "8";
            opacity: 1;
        }
        90%, 100% {
            content: "9";
            opacity: 0;
        }
    }
}