section.fixtures-standings-section {
    .group-header {
		height: 32px;
		width: 100%;
		padding: 0 8px;
		color: #505050;
		font-weight: 400;
		font-size: 13px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 4px;
		background-color: #fff;
		border-radius: 8px 8px 0 0;
	}
}