@mixin small-view {
	@media (min-width: 576px) {
		@content;
	}
}

@mixin medium-view {
	@media (min-width: 768px) {
		@content;
	}
}

@mixin large-view {
	@media (min-width: 992px) {
		@content;
	}
}

@mixin x-large-view {
	@media (min-width: 1200px) {
		@content;
	}
}

@mixin x-x-large-view {
	@media (min-width: 1400px) {
		@content;
	}
}

@function shade($color, $percentage) {
	@return mix(black, $color, $percentage);
}

@function tint($color, $percentage) {
	@return mix(white, $color, $percentage);
}

@mixin tint-value($className, $colorName, $property, $color, $through: 4, $steps: 20) {
	@for $i from 1 through $through {
		.#{$className}-#{$colorName}-#{$i}00t {
			#{$property}: tint($color, 100 - ($i * $steps));
		}
	}
}

@mixin shade-value($className, $colorName, $property, $colorVariable) {
	@for $i from 6 through 9 {
		.#{$className}-#{$colorName}-#{$i}00s {
			#{$property}: shade($colorVariable, ($i - 5) * 20);
		}
	}
}

@mixin key-value-color($name, $property) {
	$colorsList: (
		'primary': $primary,
		'secondary': $secondary,
		'success': $success,
		'warning': $warning,
		'error': $error,
		'black': $black,
		'white': $white,
		'neutral': $neutral,
	);

	@each $colorName, $colorVariable in $colorsList {
		.#{$name}-#{$colorName} {
			#{$property}: $colorVariable;
		}
		@if ($colorName == 'secondary') or ($colorName == 'neutral') {
			@include tint-value($name, $colorName, $property, $colorVariable, 10, 10);
		} @else {
			@include tint-value($name, $colorName, $property, $colorVariable);
		}
		@include shade-value($name, $colorName, $property, $colorVariable);
	}
}

@mixin input-size {
	&.fullwidth {
		width: 100%;
	}

	&.medium {
		width: 50%;
	}

	&.small {
		width: 100%;
		max-width: 350px;
	}
}
